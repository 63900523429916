import React, { FC, useState } from 'react';

import { CouponExample } from '@assets/image/png';
import { Pagination } from '@components/common';
import { Block } from '@components/layout/block';
import { Flex } from '@components/layout/flexbox';
import { VStack } from '@components/layout/vstack';
import { Heading } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';
import { Span } from '@components/typographies/span';

export const CouponsDetailList: FC = () => {
  const [page, setPage] = useState<number>(1);

  return (
    <Block width="100%" padding="30px 30px">
      <Heading as="h2" fontSize="24px" lh="40px">
        クーポン一覧
      </Heading>
      <VStack spacing="30px" align="center" py="30px">
        <Flex direction="row" flexWrap="wrap">
          <Block flex="0 0 50%" marginBottom="2rem">
            <Span display="block" width="100%" maxWidth="300px" marginBottom="1rem">
              <img src={CouponExample} width="100%" alt="coupons icons" />
            </Span>
            <Paragraph fontSize="14px" fontWeight="700" my="1rem">
              新規登録ありがとうクーポン
            </Paragraph>
            <Paragraph fontSize="14px" fontWeight="400" color="gray">
              有効期限 2022/09/01 - 2023/02/01
            </Paragraph>
          </Block>
          <Block flex="0 0 50%" marginBottom="2rem">
            <Span display="block" width="100%" maxWidth="300px" marginBottom="1rem">
              <img src={CouponExample} width="100%" alt="coupons icons" />
            </Span>
            <Paragraph fontSize="14px" fontWeight="700" my="1rem">
              新規登録ありがとうクーポン
            </Paragraph>
            <Paragraph fontSize="14px" fontWeight="400" color="gray">
              有効期限 2022/09/01 - 2023/02/01
            </Paragraph>
          </Block>
          <Block flex="0 0 50%" marginBottom="2rem">
            <Span display="block" width="100%" maxWidth="300px" marginBottom="1rem">
              <img src={CouponExample} width="100%" alt="coupons icons" />
            </Span>
            <Paragraph fontSize="14px" fontWeight="700" my="1rem">
              新規登録ありがとうクーポン
            </Paragraph>
            <Paragraph fontSize="14px" fontWeight="400" color="gray">
              有効期限 2022/09/01 - 2023/02/01
            </Paragraph>
          </Block>
          <Block flex="0 0 50%" marginBottom="2rem">
            <Span display="block" width="100%" maxWidth="300px" marginBottom="1rem">
              <img src={CouponExample} width="100%" alt="coupons icons" />
            </Span>
            <Paragraph fontSize="14px" fontWeight="700" my="1rem">
              新規登録ありがとうクーポン
            </Paragraph>
            <Paragraph fontSize="14px" fontWeight="400" color="gray">
              有効期限 2022/09/01 - 2023/02/01
            </Paragraph>
          </Block>
        </Flex>
        <Pagination currentPage={page} setCurrentPage={setPage} totalPage={5}></Pagination>
      </VStack>
    </Block>
  );
};
